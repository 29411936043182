import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

/*eslint-disable */
let isDark = false;
const user = localStorage.getItem("user") as any;
if (user && typeof user === 'string') isDark = JSON.parse(user)?.darkTheme;
export default new Vuetify({
    theme: {
        themes: {},
        dark: isDark
    }
});
