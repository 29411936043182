import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/',
    component: () => import('@/views/Index.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/users',
        name: 'Usuários',
        component: () => import('@/components/Users.vue'),
      },
      {
        path: '/assets/dashboard',
        name: 'Dashboard de Patrimonio',
        component: () => import('@/views/PatrimonyReport.vue'),
      },
      {
        path: '/assets',
        name: 'Ativos',
        component: () => import('@/views/AssetList.vue'),
      },
      {
        path: '/asset/create',
        name: 'Criar Ativo',
        component: () => import('@/views/AssetForm.vue'),
      },
      {
        path: '/asset/:id/edit',
        name: 'Editar Ativo',
        component: () => import('@/views/AssetForm.vue'),
      },
      {
        path: '/user/group-users',
        name: 'Grupos de Usuários',
        component: () => import('@/components/GroupUsers.vue'),
      },
      {
        path: '/product/categories',
        name: 'Categorias de Produtos',
        component: () => import('@/components/products/Categories.vue'),
      },
      {
        path: '/product/categories/:new',
        name: 'Criar Categoria de Produto',
        component: () => import('@/components/products/Categories.vue'),
      },
      {
        path: '/tributes',
        name: 'Tributos',
        component: () => import('@/components/tributes/Tributes.vue'),
      },
      {
        path: '/products',
        name: 'Produtos',
        component: () => import('@/components/products/ProductsList.vue'),
      },
      {
        path: '/products/register',
        name: 'Registrar Produto',
        component: () => import('@/components/products/ProductForm.vue'),
      },
      {
        path: '/company/suppliers',
        name: 'Fornecedores Empresas',
        component: () => import('@/components/companies/Supplier.vue'),
      },
      {
        path: '/accounting/documents',
        name: 'Documentos',
        component: () => import('@/components/tributes/DocumentType.vue'),
      },
      {
        path: '/stock/movements',
        name: 'Movimento de Estoque',
        component: () => import('@/components/stock/StockMovement.vue'),
      },
      {
        path: '/person/members',
        name: 'Membros',
        component: () => import('@/components/customers/Customer.vue'),
      },
      {
        path: '/base/sales',
        name: 'Vendas',
        component: () => import('@/components/base/Sales.vue'),
      },
      {
        path: '/base/sale/create',
        name: 'Nova Venda',
        component: () => import('@/components/base/SalesForm.vue'),
      },
      {
        path: '/base/sale/:id/edit',
        name: 'Editar Venda',
        component: () => import('@/components/base/SalesForm.vue'),
      },
      {
        path: '/finance/categories',
        name: 'Classificações Financeiras',
        component: () => import('@/components/finance/FinancialCategory.vue'),
      },
      {
        path: '/finance/account-reports',
        name: 'Extrato de Conta',
        component: () => import('@/components/finance/AccountReport.vue'),
      },
      {
        path: '/customers/birthdays',
        name: 'Aniversariantes',
        component: () => import('@/components/base/CustomerBirth.vue'),
      },
      {
        path: '/finance/accounts',
        name: 'Contas Financeiras',
        component: () => import('@/components/finance/FinancialAccount.vue'),
      },
      {
        path: '/finance/movements',
        name: 'Lançamentos Financeiros',
        component: () => import('@/components/finance/FinancialMovement.vue'),
      },
      {
        path: '/finance/movement/instalments',
        name: 'Parcelamentos',
        component: () => import('@/components/finance/FinancialMovementInstalment.vue'),
      },
      {
        path: '/finance/account-payables',
        name: 'Contas a Pagar',
        component: () => import('@/components/finance/AccountPayable.vue'),
      },
      {
        path: '/finance/report/account-receivables',
        name: 'Relatório Contas a Receber',
        component: () => import('@/components/finance/AccountReceivableReport.vue'),
      },
      {
        path: '/finance/report/account-payables',
        name: 'Relatório Contas a Pagar',
        component: () => import('@/components/finance/AccountPayableReport.vue'),
      },
      {
        path: '/finance/payment-methods',
        name: 'Métodos de Pagamento',
        component: () => import('@/components/finance/PaymentMethod.vue'),
      },
      {
        path: '/finance/transaction/create',
        name: 'Nova Transação Financeira',
        component: () => import('@/components/finance/FinancialTransactionForm.vue'),
      },

      /* Immobiles */
      {
        path: '/immobile/owners',
        name: 'Cadastro de Proprietários',
        component: () => import('@/components/immobile/Owner.vue'),
      },
      {
        path: '/immobile/customers',
        name: 'Cadastro de Clientes Immobile',
        component: () => import('@/components/immobile/Customer.vue'),
      },
      {
        path: '/immobile/condominiums',
        name: 'Cadastro de Condominios',
        component: () => import('@/components/immobile/Condominium.vue'),
      },
      {
        path: '/base/employees',
        name: 'Cadastro de Colaboradores',
        component: () => import('@/components/immobile/Employee.vue'),
      },
      {
        path: '/immobiles',
        name: 'Cadastro de Imóveis',
        component: () => import('@/components/immobile/Immobile.vue'),
      },
      /* base */
      {
        path: '/base/customers',
        name: 'Clientes',
        component: () => import('@/components/base/Customer.vue'),
      },
      {
        path: '/base/customers/create',
        name: 'Cadastro de Clientes',
        component: () => import('@/views/CustomerForm.vue'),
      },
      {
        path: '/base/customers/:id/edit',
        name: 'Edição de Clientes',
        component: () => import('@/views/CustomerForm.vue'),
      },
      {
        path: '/base/suppliers',
        name: 'Fornecedores',
        component: () => import('@/components/base/Supplier.vue'),
      },
      {
        path: '/base/products',
        name: 'Produtos Base',
        component: () => import('@/components/base/Product.vue'),
      },
      {
        path: '/base/categories',
        name: 'Categorias de Produto',
        component: () => import('@/components/base/Categories.vue'),
      },
      {
        path: '/base/product/:id/edit',
        name: 'Editar Produto',
        component: () => import('@/components/base/ProductForm.vue'),
      },
      {
        path: '/base/product/create',
        name: 'Novo Produto',
        component: () => import('@/components/base/ProductForm.vue'),
      },
      {
        path: '/base/sales/home',
        name: 'Home Vendas',
        component: () => import('@/components/base/SalesHome.vue'),
      },
      {
        path: '/base/sales/billing',
        name: 'Faturamento Vendas',
        component: () => import('@/components/base/SalesBilling.vue'),
      },
      {
        path: '/base/stock-transactions',
        name: 'Transação de Estoque',
        component: () => import('@/components/base/StockTransactions.vue'),
      },
      {
        path: '/base/stock-transactions/create',
        name: 'Nova Transação de Estoque',
        component: () => import('@/components/base/StockTransactionForm.vue'),
      },
      {
        path: '/base/stock-transactions/:id/edit',
        name: 'Editar Transação de Estoque',
        component: () => import('@/components/base/StockTransactionForm.vue'),
      },
      {
        path: '/gerentor/comission-transactions',
        name: 'Relatórios de Comissões',
        component: () => import('@/components/base/ComissionTransaction.vue'),
      },
      // Pedbem
      {
        path: '/pedbem/products',
        name: 'Produtos Pedbem',
        component: () => import('@/components/delivery/DeliveryProduct.vue'),
      },
      {
        path: '/pedbem/product/register',
        name: 'Novo Produto Pedbem',
        component: () => import('@/components/delivery/DeliveryProductForm.vue'),
      },
      {
        path: '/pedbem/product/:id/edit',
        name: 'Editar Produto Pedbem',
        component: () => import('@/components/delivery/DeliveryProductForm.vue'),
      },
      {
        path: '/pedbem/delivered-sales',
        name: 'Pedidos Entregues',
        component: () => import('@/components/delivery/carts/ShoppingCart.vue'),
      },
      {
        path: '/pedbem/sales-in-progress',
        name: 'Pedidos Em Andamento',
        component: () => import('@/components/delivery/carts/ShoppingCartInProgress.vue'),
      },
      {
        path: '/pedbem/contacts',
        name: 'Contatos',
        component: () => import('@/components/delivery/Contacts.vue'),
      },
      {
        path: '/schedules',
        name: 'Agenda',
        component: () => import('@/components/base/schedule.vue'),
      },
      {
        path: '/schedule/list',
        name: 'Listagem Agenda',
        component: () => import('@/components/base/ScheduleList.vue'),
      },
      /* opportunities */
      {
        path: '/opportunities',
        name: 'Opportunidades',
        component: () => import('@/components/base/Opportunity.vue'),
      },
      {
        path: '/opportunities/create',
        name: 'Criar Opportunidades',
        component: () => import('@/components/base/OpportunityForm.vue'),
      },
    ],
  },
];
const router = new VueRouter({
  mode: !process.env.IS_ELECTRON ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
