/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import './plugins/chartist'
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
const VueMask = require('v-mask');
const money = require('v-money');

Vue.use(VueMask, money);


const { VueMaskDirective } = require('v-mask');
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
